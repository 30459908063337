<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Master Jadwal</strong>
              </h5>
            </template>
            <!-- <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-button variant="primary" v-b-modal.modal-input
                    ><CIcon name="cil-plus" /> Tambah Data</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <hr />
                </b-col>
              </b-row>
   -->
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            
            <b-row>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="tanggal_jadwal">Tanggal Jadwal</label>
                <date-picker
                  style="width: 100%"
                  id="tanggal_jadwal"
                  format="DD MMMM YYYY"
                  type="datet"
                  v-model="dataFilter.tanggal_jadwal"
                ></date-picker>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="ms_poli_id">Poliklinik</label>
                <multiselect
                  id="ms_poli_id"
                  v-model="dataFilter.ms_poli_id"
                  :options="listPoliklinik"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_poli"
                  placeholder="-- Pilih Poliklinik --"
                  size="sm"
                ></multiselect>
              </b-col>
              <b-col cols="4" sm="4" md="4" lg="4">
                <label for="status_jadwal">Status</label>
                <multiselect
                  id="status_jadwal"
                  v-model="dataFilter.status_jadwal"
                  :options="$store.state.data_static.option"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  placeholder="-- Pilih Status --"
                  size="sm"
                ></multiselect>
              </b-col>
            </b-row>

            <b-row align-h="end">
              <b-col cols="auto">
                <b-button
                  style="margin:10px"
                  @click="reset()"
                  variant="danger"
                  :disabled="tableBusy"
                  >Reset
                </b-button>
                <b-button
                  @click="getDatas()"
                  variant="primary"
                  :disabled="tableBusy"
                  >Search
                </b-button>
              </b-col>
            </b-row>
            <hr>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <!-- <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Jadwal'"
                      v-b-modal.modal-edit-jadwal
                      @click="is_data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    > -->
                    <b-button
                      v-if="item.item.status_jadwal == 1 && (role == 'super admin' || role == 'admin')"
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Nonaktifkan Jadwal'"
                      v-b-modal.modal-delete-jadwal
                      @click="is_data = item.item"
                    >
                       {{ item.actions }}
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                          <path d="M7.5 1v7h1V1h-1z"/>
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                        </svg>
                    </b-button>
                    <b-button
                      v-if="item.item.status_jadwal == 0 && (role == 'super admin' || role == 'admin')"
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Aktifkan Jadwal'"
                      v-b-modal.modal-aktif-jadwal
                      @click="is_data = item.item"
                    >
                       {{ item.actions }}
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                          <path d="M7.5 1v7h1V1h-1z"/>
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                        </svg>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <modal-input
        @alertFromChild="triggerAlert($event), getDatas()"
        :list_poli="list_poli"
      /> -->
    <modal-edit
      :data_edit="is_data"
      :list_poli="list_poli"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-aktif
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalAktif from "./modalAktif.vue";
// import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    // ModalInput,
    ModalDelete,
    ModalAktif,
    ModalEdit,
  },
  name: "master_dokter",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      dataFilter: {
        tanggal_jadwal: new Date(),
        ms_poli_id: null,
        status_jadwal: null,
      },
      list_poli: [],
      listPoliklinik: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_poli",
          label: "Nama Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tgl_jadwal",
          label: "Tgl. Jadwal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "hari_jadwal",
          label: "Hari",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "waktu",
        //   label: "Jam",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
    role() {
      return this.$store.state.user_role
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
    this.getList();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this
      vm.tableBusy = true;
      let res = await vm.$axios.post("/jadwal/list", {
        ...vm.dataFilter,
        status_jadwal: vm.dataFilter.status_jadwal ? (vm.dataFilter.status_jadwal.value ? '1' : '0') : undefined,
        ms_poli_id: vm.dataFilter.ms_poli_id ? vm.dataFilter.ms_poli_id.ms_poli_id : undefined,
        tanggal_jadwal: vm.dataFilter.tanggal_jadwal ? vm.$moment(vm.dataFilter.tanggal_jadwal) : undefined,
        halaman: 0,
        jumlah: 1000,
      });
      // console.log(res);
      vm.items = res.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.waktu = `${x.waktu_mulai}-${x.waktu_selesai}`;
        x.tgl_jadwal = vm.$moment(x.tanggal_jadwal).format("LL");
        if (x.status_jadwal == 1) {
          x.status = "Aktif";
        } else {
          x.status = "Tidak Aktif";
        }
      }
      vm.totalRows = vm.items.length;
      vm.tableBusy = false;

      let poli = await vm.$axios.post("/ms_poli/list", {
        halaman: 1,
        jumlah: 1000,
      });
      vm.list_poli = poli.data.data;
    },
    async getList(){
      const vm = this
      vm.tableBusy = true
      try {
        // poliklinik
        let listPoliklinik = await vm.$axios.post('/ms_poli/list')
        vm.listPoliklinik = listPoliklinik.data.status == 200 ? listPoliklinik.data.data : []
      } catch (err) {
        if (err.message) {
          vm.triggerAlert({ variant: "danger", msg:err.message.toUpperCase(), showing: true });
        } else {
          vm.triggerAlert({ variant: "danger", msg:err.toUpperCase(), showing: true });
        }
      } finally {
        vm.tableBusy = false;
      }
    },
    async reset(){
      const vm = this
      vm.dataFilter = {
        tanggal_jadwal: new Date(),
        ms_poli_id: null,
        status_jadwal: null,
      }
      vm.getDatas();
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
  },
};
</script>
